import { Grid, SpaceBetween, Button } from '@cloudscape-design/components';
import { decideButton } from '../../../helpers/decideButtonDisabled';
import { filterEnum, Controls } from '../../../models/interfaces';
import { useContext, useEffect, useState } from 'react';
import { DocumentContext } from '../../../context/documentContext';
import './documentControlButtons.css';
import { BurdenAttributionView } from './burdenAttributionView';

export const DocumentControlButtons = (props: { domain: string }) => {
  const { domain } = props;
  const {
    docViewFilter,
    setDocViewFilter,
    updateRedline,
    updateFocus,
    selectedItems,
    controlFilter,
    setControlFilter,
    controlsNavigation,
    updateStarred,
    controls
  } = useContext(DocumentContext);
  const [buttnsArray, setButtonArray] = useState<any[]>([]);
  const [buttonNames, setButtonNames] = useState<{ [key: string]: string }>({});
  const [showBurdenAttribution, setShowBurdenAttribution] =
    useState<boolean>(false);

  useEffect(() => {
    const tempValue: { [key: string]: string } = {};
    controls.forEach((ele) => (tempValue[ele.controlName] = ele.controlButton));
    setButtonNames(tempValue);
  }, []);

  const constantButtons = [
    {
      domainCode: '(CON)',
      button: (
        <div className="button">
          <Button
            onClick={() => setDocViewFilter(filterEnum.location)}
            variant={
              docViewFilter === filterEnum.location ? 'primary' : 'normal'
            }
          >
            SERVICE LEVEL REQUIREMENTS
          </Button>
        </div>
      )
    },
    {
      domainCode: '(CON)',
      button: (
        <div className="button">
          <Button
            onClick={() => setDocViewFilter(filterEnum.terminiation)}
            variant={
              docViewFilter === filterEnum.terminiation ? 'primary' : 'normal'
            }
          >
            TERMINATIONS
          </Button>
        </div>
      )
    },
    {
      domainCode: '(CON)',
      button: (
        <div className="button">
          <Button
            onClick={() => setDocViewFilter(filterEnum.Financial_Information)}
            variant={
              docViewFilter === filterEnum.Financial_Information
                ? 'primary'
                : 'normal'
            }
          >
            FINANCIAL INFORMATION
          </Button>
        </div>
      )
    },
    {
      domainCode: '(RES)',
      button: (
        <div className="button">
          <Button
            onClick={() => setDocViewFilter(filterEnum.location)}
            variant={
              docViewFilter === filterEnum.location ? 'primary' : 'normal'
            }
          >
            PLACES &  LOCATIONS
          </Button>
        </div>
      )
    },
    {
      domainCode: '(RES)',
      button: (
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.burden_attribution);
              setShowBurdenAttribution(true);
            }}
            variant={
              docViewFilter === filterEnum.burden_attribution
                ? 'primary'
                : 'normal'
            }
          >
            BURDEN ASSIGNMENT
          </Button>
        </div>
      )
    },
    {
      domainCode: '(RES)',
      button: (
        <div className="button">
          <Button
            onClick={() => setDocViewFilter(filterEnum.financial_due_dates)}
            variant={
              docViewFilter === filterEnum.financial_due_dates
                ? 'primary'
                : 'normal'
            }
          >
            FINANCIAL DUE DATES
          </Button>
        </div>
      )
    }
  ];
  useEffect(() => {
    console.log('controls nav', controlsNavigation);
    const toInclude = Object.keys(controlsNavigation);
    const tempButtonArray = [];
    const thisDomainControls = controls.filter(
      (ele) => ele.domainCode === domain && toInclude.includes(ele.controlName)
    );
    for (const control of thisDomainControls) {
      tempButtonArray.push(
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.control);
              setControlFilter(control.controlName);
            }}
            // disabled={decideButton(cont, controlsNavigation)}
            variant={
              controlFilter === control.controlName ? 'primary' : 'normal'
            }
          >
            {control.controlButton}
          </Button>
        </div>
      );
    }
    const constButtons = constantButtons.filter(
      (ele) => ele.domainCode === domain
    );
    for (const conButton of constButtons) {
      tempButtonArray.push(conButton.button);
    }
    setButtonArray(tempButtonArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlsNavigation, controlFilter, controls]);

  return (
    <div className="buttonContainer">
      <>
        {!showBurdenAttribution ? <>{buttnsArray}</> : null}
        {showBurdenAttribution ? <BurdenAttributionView /> : null}
      </>
    </div>
  );
};
