import * as React from 'react';
import {
  TopNavigation
} from '@cloudscape-design/components';
import { logoSting } from '../../helpers/logoString';
import { UserContext } from '../../context/authContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// const i18nStrings = {
//   overflowMenuBackIconAriaLabel: 'Back',
//   overflowMenuDismissIconAriaLabel: 'Close menu'
// };

export const TopNavigationBar = () => {
  const { logout, token, user } = useContext(UserContext);
  const navigate = useNavigate();

  const logoutLocal = (item: { detail: { id: string } }): void => {
    console.log('item ', item);
    if (item.detail.id === 'logout') {
      logout();
    }
    if(item.detail.id === "changePassword") {
      navigate('changepassword')
    }
  };

  return (
    <TopNavigation
      className="b"
      identity={{
        href: '/app/landing',
        title: 'Koru Compliance Platform',
        logo: { src: logoSting, alt: 'Service' }
      }}
      utilities={
        token
          ? [
              {
                type: 'button',
                iconName: `notification`,
                title: 'Notifications',
                ariaLabel: 'Notifications (unread)',
                badge: true,
                disableUtilityCollapse: false,
                // onClick: () => alert('clicked')
              },
              {
                type: 'menu-dropdown',
                text: `${user.name}`,
                description: `${user.username}`,
                iconName: 'user-profile',
                items: [
                  // { id: 'profile', text: 'Profile' },
                  { id: 'changePassword', text: 'Change Password' },
                  { id: 'logout', text: 'Log Out' }
                ],
                onItemClick: (item) => logoutLocal(item)
              }
            ]
          : []
      }
    />
  );
};
