import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { DocumentContextProvider } from './context/documentContext';
import { UserContextProvider } from './context/authContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LoginPage } from './Screens/LoginPage';
import { ProtectedRoute } from './Screens/ProtectedRoute';
import { Routing } from './Screens/Routing';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
console.log('Process end value', process.env.REACT_APP_API);

root.render(
  <React.StrictMode>
    {/* <UserContextProvider> */}
    <Routing />
    {/* </UserContextProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
