import { useState, useEffect, useContext } from 'react';
import { DocumentContext } from '../../../context/documentContext';
import { Button, Tabs } from '@cloudscape-design/components';
import { DocumentControlButtons } from './documentControlButtons';
export const ButtonWrapper = () => {
  const { docViewFilter, domains } = useContext(DocumentContext);
  const [tabArray, setTabArray] = useState<any[]>([]);

  useEffect(() => {
    const tempArray = [];
    for (const domain of domains) {
      tempArray.push({
        label: `${domain.domainName.split(')')[1].trim()}`,
        id: `${domain.domainCode}`,
        content: <DocumentControlButtons domain={domain.domainCode} />
      });
    }
    setTabArray(tempArray);
  }, [domains]);

  return (
    <>
      <Tabs tabs={tabArray} />
    </>
  );
};
