import { useState, useEffect, useContext } from 'react';
import {
  Table,
  Container,
  Button,
  SpaceBetween,
  Header,
  Box,
  Spinner,
  ProgressBar,
  Icon,
  Modal,
  RadioGroup,
  Select,
  Pagination
} from '@cloudscape-design/components';

// models
import { documentTableItem, KBOptions, Projects } from '../models/interfaces';
import { useCollection } from '@cloudscape-design/collection-hooks';
// helpers
// dummy data
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { DocumentContext } from '../context/documentContext';
import { UserContext } from '../context/authContext';
import { ProjectForm } from './Components/projectForm';
import { getProjectType } from '../helpers/projectType';
import { ProjectContext } from '../context/projectContext';

export const ProjectTableView = (props: {
  // ssetSelectedProject: (arg: Projects[]) => void;
  // projects: Projects[];
  // setProjects: (arg: Projects[]) => void;
  // selectedProjects: Projects[];
  setViewFolder: (arg: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { setViewFolder } = props;
  const { addDocToKnowledgeBase, resetDocumentState, setCurrentFolder } =
    useContext(DocumentContext);
  const {
    mainProjects,
    getAllProjects,
    selectedProjects,
    setSelectedProjects
  } = useContext(ProjectContext);
  const { auth, user } = useContext(UserContext);

  const [folderModal, setFolderModal] = useState<boolean>(false);
  const [addProjectLoading, setAddProjectLoading] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  // state

  const createNewProject = async (
    projectName: string,
    projectDescription: string,
    projectType: string
  ) => {
    try {
      setAddProjectLoading(true);
      const createProject = await axios.post(
        `${process.env.REACT_APP_KORU_API}/folder?type=createNewProject`,
        {
          projectName,
          projectDescription,
          projectType,
          owner: user.name
        },
        auth
      );
      console.log('Folder response', createProject);
      getAllProjects();
      setAddProjectLoading(false);
      setFolderModal(false);
    } catch (e) {
      const error = e as Error;
      console.log('Error', error.message);
    }
  };
  useEffect(() => {
    console.log('running use effect search params changes');
    const projectQuery = searchParams.get('projectId');
    console.log('project qquery', projectQuery);

    if (projectQuery) {
      const thisProject = mainProjects.filter(
        (ele) => ele._id === projectQuery
      );
      setSelectedProjects(thisProject);
      setViewFolder(true);
    } else {
      console.log('no query param');
      setViewFolder(false);
      setSelectedProjects([]);
    }
    // getAllProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    getAllProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const deleteProject = async () => {
    try {
      const results = await axios.post(
        `${process.env.REACT_APP_KORU_API}/folder?type=deleteProject`,
        {
          _id: selectedProjects[0]._id
        },
        auth
      );
      setSelectedProjects([]);
      getAllProjects();
    } catch (e) {
      const error = e as Error;
      console.log('Error', error.message);
    }
  };

  const emptyState = () => {
    if (mainProjects.length === 0 && !fetching) {
      return (
        <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No Projects</b>
            <Button onClick={() => setFolderModal(true)}>
              Create Project To Start
            </Button>
          </SpaceBetween>
        </Box>
      );
    }
  };

  const {
    items,
    actions,
    filteredItemsCount,
    // collectionProps,
    filterProps,
    paginationProps
  } = useCollection(mainProjects, {
    filtering: {
      empty: <Box>Nothing</Box>,
      noMatch: <Box>No matches</Box>
    },
    pagination: { pageSize: 15 },
    sorting: {},
    selection: {}
  });

  return (
    <>
      <Container
        header={
          <Header
            variant="h1"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                {/* <Button onClick={() => fixProjects()}>Fix</Button> */}
                <Button
                  disabled={!(selectedProjects && selectedProjects.length > 0)}
                  onClick={() => {
                    setViewFolder(true);
                    setSearchParams(`projectId=${selectedProjects[0]._id}`);
                    setCurrentFolder('');
                  }}
                >
                  View Project
                </Button>
                <Button
                  onClick={() => deleteProject()}
                  disabled={!(selectedProjects && selectedProjects.length > 0)}
                >
                  Delete Project
                </Button>
                <Button
                  onClick={() => setFolderModal(true)}
                  loading={addProjectLoading}
                >
                  Create Project
                </Button>
              </SpaceBetween>
            }
          >
            Projects
          </Header>
        }
      >
        <div style={{ overflow: 'scroll' }}>
          <Table
            // onSelectionChange={({ detail }) =>
            //   setSelectedItemsLocal(detail.selectedItemsLocal)
            // }
            // {...collectionProps}
            contentDensity="compact"
            items={items}
            selectionType="single"
            pagination={<Pagination {...paginationProps} />}
            columnDefinitions={[
              {
                id: 'name',
                header: 'Project Name',
                cell: (item: Projects) => item.projectName,
                sortingField: 'documentFileName',
                maxWidth: '400px'
              },
              {
                id: 'description',
                header: 'Description',
                cell: (item: Projects) => item.projectDescription,
                maxWidth: '200px'
              },
              {
                id: 'owner',
                header: 'Project Owner',
                cell: (item: Projects) => item.owner,
                sortingField: 'owner',
                maxWidth: '200px'
              },
              {
                id: 'type',
                header: 'Type',
                cell: (item: Projects) => getProjectType(item.projectType),
                maxWidth: '200px'
              },
              {
                id: 'date',
                header: 'Date Created',
                cell: (item: Projects) =>
                  new Date(item.createdAt).toLocaleDateString(),
                sortingField: 'date',
                sortingComparator(a, b) {
                  return (
                    new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                  );
                }
              }
            ]}
            loadingText="Loading resources"
            empty={emptyState()}
            onSelectionChange={({ detail }) => {
              setSelectedProjects(detail.selectedItems);
            }}
            selectedItems={selectedProjects}
          />
        </div>
      </Container>
      <Modal visible={folderModal} onDismiss={() => setFolderModal(false)}>
        <ProjectForm
          createNewProject={createNewProject}
          setFolderModal={setFolderModal}
        />
      </Modal>
    </>
  );
};
